<template>
  <div class="mt-5">
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="tableSimpleHeader sampleTableBg">No</th>
            <th class="tableSimpleHeader sampleTableBg">Date</th>
            <th class="tableSimpleHeader sampleTableBg">Number Of Push</th>
            <th class="tableSimpleHeader sampleTableBg">Prepaid</th>
            <th class="tableSimpleHeader sampleTableBg">Postpaid</th>
            <th class="tableSimpleHeader sampleTableBg">Total</th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item) in reportData">
            <tr
              v-for="(pushs, index) in item.pushs"
              :key="index">
              <td class="tableVerticalTop"
                v-if="index === 0" 
                :rowspan="item.pushs.length">{{ item.no }}</td>
              <td class="tableSimpleHeader tableVerticalTop"
                 v-if="index === 0" 
                :rowspan="item.pushs.length">{{ item.date | formatDateNotTime }}</td>
              <td class="tableSimpleHeader">{{ pushs.numberOfPush | toNumber }}</td>
              <td class="tableSimpleHeader">{{ pushs.prepaid | toNumber }}</td>
              <td class="tableSimpleHeader">{{ pushs.postpaid | toNumber }}</td>
              <td class="tableSimpleHeader">{{ pushs.total | toNumber }}</td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: 'ReportContactByMessagesDaily',
  props: {
    reportData: {
      type: Array
    },

    loadReport: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  }
}
</script>